<template>
  <div class="page-container">
    <Headbar>
      <template v-slot:left>
        <h1 v-if="original">{{ original.attributes.name }}</h1>
      </template>
      <template v-slot:right>
        <Button v-if="$store.getters.hasPermission('update users')" :class="{spinner: is_saving}"
                :onclick="save" className="--primary --small --wider">
          {{ $t('save') }}
        </Button>
      </template>
    </Headbar>
    <main>
      <Form class="form">
        <SectionHeader :title="$t('blinds.blind_details')"/>
        <div class="form-body">
          <FormGroupThree>
            <FormInputText v-model="$v.blind.name.$model" :disabled="is_saving" :has-error="$v.blind.name.$error"
                           :label="$t('blinds.name')" :placeholder="$t('blinds.name')" identifier="name">
              <template v-slot:errors>
                <p v-if="!$v.blind.name.required">
                  {{ $t('validation.x_is_required', {x: $t('blinds.name')}) }}
                </p>
              </template>
            </FormInputText>

            <FormInputSelect v-model="$v.blind.type.$model" :disabled="is_saving"
                             :display-custom-label="(row) => `${row.name}`" :has-error="$v.blind.type.$error"
                             :label="$t('blinds.type')" :options="blindTypeOptions"
                             :placeholder="$t('blinds.type')" identifier="role"
                             track-by="name">
              <template v-slot:errors>
                <p v-if="!$v.blind.type.required">
                  {{ $t('validation.x_is_required', {x: $t('blinds.type')}) }}
                </p>
              </template>
            </FormInputSelect>

            <FormInputText v-if="showWidthDivider"
                           v-model.number="$v.blind.width_divider.$model"
                           :disabled="is_saving"
                           type="number"
                           :has-error="$v.blind.width_divider.$error"
                           :label="$t('blinds.width_divider')" :placeholder="$t('blinds.width_divider')"
                           identifier="width_divider">
              <template v-slot:errors>
                <p v-if="!$v.blind.width_divider.required">
                  {{ $t('validation.x_is_required', {x: $t('blinds.width_divider')}) }}
                </p>
              </template>
            </FormInputText>
          </FormGroupThree>
        </div>
      </Form>
      <Form v-if="hasMultipleMeasurements && (measurements_back_bar || measurements_brackets || measurements_cover)"
            class="form">
        <SectionHeader :onclick="toggleAddMeasurement" :title="$t('blinds.measurement_details')" button="Add"/>
        <div class="flex flex-row gap-x-2 mt-4 ml-4">
          <Button v-for="item in measurementTabs"
                  :key="item"
                  :class-name="currentMeasurementTab === item ? '--primary' : '--secondary'"
                  :onclick="() => currentMeasurementTab = item"
                  class="w-36">
            {{ item }}
          </Button>
        </div>
        <div v-if="currentMeasurementTab === 'Back Bar'" class="form-body">
          <div class="input-group-three-div">
            <div v-for="(v, index) in $v.measurements_back_bar.$each.$iter"
                 class="flex flex-row gap-x-2 input-group-div pr-8">
              <FormInputText :key="v.id.$model"
                             v-model.number="v.value.$model"
                             :disabled="is_saving"
                             :has-error="v.value.$error"
                             :identifier="v.id.$model"
                             :label="$t(`measurements.${v.id.$model}`) + `${v.id.$model !== 'gear_and_top_tube' && v.id.$model !== 'gear_and_top_tube_optional' && v.id.$model !== 'extra_fabric_cutting_clearance' ? '(cm)' : ''}`"
                             :placeholder="$t(`measurements.${v.id.$model}`)"
                             class="w-full"
                             type="number">
                <template v-slot:errors>
                  <p v-if="v.value.$anyError">
                    {{ $t('validation.number_must_be_atleast', {x: 0}) }}
                  </p>
                </template>
              </FormInputText>
              <font-awesome-icon :icon="['fal', 'minus-circle']"
                                 class="text-negative text-lg cursor-pointer mt-10 ml-auto hover:text-primary-over"
                                 @click="toggleRemoveMeasurement(v, 'back_bar')"/>
              <div class="arrows-container mt-6 ml-2">
                <font-awesome-icon :icon="['fal', 'chevron-up']" class="cursor-pointer"
                                   @click="updateMeasurementOrderBackBar('up', v)"/>
                <font-awesome-icon :icon="['fal', 'chevron-down']" class="cursor-pointer"
                                   @click="updateMeasurementOrderBackBar('down', v)"/>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="currentMeasurementTab === 'Brackets'" class="form-body">
          <div class="input-group-three-div">
            <div v-for="(v, index) in $v.measurements_brackets.$each.$iter"
                 class="flex flex-row gap-x-2 input-group-div pr-8">
              <FormInputText :key="v.id.$model"
                             v-model.number="v.value.$model"
                             :disabled="is_saving"
                             :has-error="v.value.$error"
                             :identifier="v.id.$model"
                             :label="$t(`measurements.${v.id.$model}`) + `${v.id.$model !== 'gear_and_top_tube' && v.id.$model !== 'gear_and_top_tube_optional' && v.id.$model !== 'extra_fabric_cutting_clearance' ? '(cm)' : ''}`"
                             :placeholder="$t(`measurements.${v.id.$model}`)"
                             class="w-full"
                             type="number">
                <template v-slot:errors>
                  <p v-if="v.value.$anyError">
                    {{ $t('validation.number_must_be_atleast', {x: 0}) }}
                  </p>
                </template>
              </FormInputText>
              <font-awesome-icon :icon="['fal', 'minus-circle']"
                                 class="text-negative text-lg cursor-pointer mt-10 ml-auto hover:text-primary-over"
                                 @click="toggleRemoveMeasurement(v, 'brackets')"/>
              <div class="arrows-container mt-6 ml-2">
                <font-awesome-icon :icon="['fal', 'chevron-up']" class="cursor-pointer"
                                   @click="updateMeasurementOrderBrackets('up', v)"/>
                <font-awesome-icon :icon="['fal', 'chevron-down']" class="cursor-pointer"
                                   @click="updateMeasurementOrderBrackets('down', v)"/>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="currentMeasurementTab === 'Cover'" class="form-body">
          <div class="input-group-three-div">
            <div v-for="(v, index) in $v.measurements_cover.$each.$iter"
                 class="flex flex-row gap-x-2 input-group-div pr-8">
              <FormInputText :key="v.id.$model"
                             v-model.number="v.value.$model"
                             :disabled="is_saving"
                             :has-error="v.value.$error"
                             :identifier="v.id.$model"
                             :label="$t(`measurements.${v.id.$model}`) + `${v.id.$model !== 'gear_and_top_tube' && v.id.$model !== 'gear_and_top_tube_optional' && v.id.$model !== 'extra_fabric_cutting_clearance' ? '(cm)' : ''}`"
                             :placeholder="$t(`measurements.${v.id.$model}`)"
                             class="w-full"
                             type="number">
                <template v-slot:errors>
                  <p v-if="v.value.$anyError">
                    {{ $t('validation.number_must_be_atleast', {x: 0}) }}
                  </p>
                </template>
              </FormInputText>
              <font-awesome-icon :icon="['fal', 'minus-circle']"
                                 class="text-negative text-lg cursor-pointer mt-10 ml-auto hover:text-primary-over"
                                 @click="toggleRemoveMeasurement(v, 'cover')"/>
              <div class="arrows-container mt-6 ml-2">
                <font-awesome-icon :icon="['fal', 'chevron-up']" class="cursor-pointer"
                                   @click="updateMeasurementOrderCover('up', v)"/>
                <font-awesome-icon :icon="['fal', 'chevron-down']" class="cursor-pointer"
                                   @click="updateMeasurementOrderCover('down', v)"/>
              </div>
            </div>
          </div>
        </div>
      </Form>
      <Form v-if="hasControlMeasurements && (measurements_cord || measurements_wand)" class="form">
        <SectionHeader :onclick="toggleAddMeasurement" :title="$t('blinds.measurement_details')" button="Add"/>
        <div class="flex flex-row gap-x-2 mt-4 ml-4">
          <Button v-for="item in measurementTabsControl"
                  :key="item"
                  :class-name="currentMeasurementTabControl === item ? '--primary' : '--secondary'"
                  :onclick="() => currentMeasurementTabControl = item"
                  class="w-36">
            {{ item }}
          </Button>
        </div>
        <div v-if="currentMeasurementTabControl === 'Cord'" class="form-body">
          <div class="input-group-three-div">
            <div v-for="(v, index) in $v.measurements_cord.$each.$iter"
                 class="flex flex-row gap-x-2 input-group-div pr-8">
              <FormInputText :key="v.id.$model"
                             v-model.number="v.value.$model"
                             :disabled="is_saving"
                             :has-error="v.value.$error"
                             :identifier="v.id.$model"
                             :label="$t(`measurements.${v.id.$model}`) + `${v.id.$model !== 'gear_and_top_tube' && v.id.$model !== 'gear_and_top_tube_optional' && v.id.$model !== 'extra_fabric_cutting_clearance' ? '(cm)' : ''}`"
                             :placeholder="$t(`measurements.${v.id.$model}`)"
                             class="w-full"
                             type="number">
                <template v-slot:errors>
                  <p v-if="v.value.$anyError">
                    {{ $t('validation.number_must_be_atleast', {x: 0}) }}
                  </p>
                </template>
              </FormInputText>
              <font-awesome-icon :icon="['fal', 'minus-circle']"
                                 class="text-negative text-lg cursor-pointer mt-10 ml-auto hover:text-primary-over"
                                 @click="toggleRemoveMeasurement(v, 'cord')"/>
              <div class="arrows-container mt-6 ml-2">
                <font-awesome-icon :icon="['fal', 'chevron-up']" class="cursor-pointer"
                                   @click="updateMeasurementOrderCord('up', v)"/>
                <font-awesome-icon :icon="['fal', 'chevron-down']" class="cursor-pointer"
                                   @click="updateMeasurementOrderCord('down', v)"/>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="currentMeasurementTabControl === 'Wand'" class="form-body">
          <div class="input-group-three-div">
            <div v-for="(v, index) in $v.measurements_wand.$each.$iter"
                 class="flex flex-row gap-x-2 input-group-div pr-8">
              <FormInputText :key="v.id.$model"
                             v-model.number="v.value.$model"
                             :disabled="is_saving"
                             :has-error="v.value.$error"
                             :identifier="v.id.$model"
                             :label="$t(`measurements.${v.id.$model}`) + `${v.id.$model !== 'gear_and_top_tube' && v.id.$model !== 'gear_and_top_tube_optional' && v.id.$model !== 'extra_fabric_cutting_clearance' ? '(cm)' : ''}`"
                             :placeholder="$t(`measurements.${v.id.$model}`)"
                             class="w-full"
                             type="number">
                <template v-slot:errors>
                  <p v-if="v.value.$anyError">
                    {{ $t('validation.number_must_be_atleast', {x: 0}) }}
                  </p>
                </template>
              </FormInputText>
              <font-awesome-icon :icon="['fal', 'minus-circle']"
                                 class="text-negative text-lg cursor-pointer mt-10 ml-auto hover:text-primary-over"
                                 @click="toggleRemoveMeasurement(v, 'wand')"/>
              <div class="arrows-container mt-6 ml-2">
                <font-awesome-icon :icon="['fal', 'chevron-up']" class="cursor-pointer"
                                   @click="updateMeasurementOrderWand('up', v)"/>
                <font-awesome-icon :icon="['fal', 'chevron-down']" class="cursor-pointer"
                                   @click="updateMeasurementOrderWand('down', v)"/>
              </div>
            </div>
          </div>
        </div>
      </Form>
      <Form v-else-if="!hasMultipleMeasurements && !hasControlMeasurements"
            class="form">
        <SectionHeader :onclick="toggleAddMeasurement" :title="$t('blinds.measurement_details')" button="Add"/>
        <div class="flex flex-row gap-x-2 mt-4 ml-4">
        </div>
        <div class="form-body">
          <div class="input-group-three-div">
            <div v-for="(v, index) in $v.measurements_general.$each.$iter"
                 class="flex flex-row gap-x-2 input-group-div pr-8">
              <FormInputText :key="v.id.$model"
                             v-model.number="v.value.$model"
                             :disabled="is_saving"
                             :has-error="v.value.$error"
                             :identifier="v.id.$model"
                             :label="$t(`measurements.${v.id.$model}`) + `${v.id.$model !== 'gear_and_top_tube' && v.id.$model !== 'gear_and_top_tube_optional' && v.id.$model !== 'extra_fabric_cutting_clearance' ? '(cm)' : ''}`"
                             :placeholder="$t(`measurements.${v.id.$model}`)"
                             class="w-full"
                             type="number">
                <template v-slot:errors>
                  <p v-if="v.value.$anyError">
                    {{ $t('validation.number_must_be_atleast', {x: 0}) }}
                  </p>
                </template>
              </FormInputText>
              <font-awesome-icon :icon="['fal', 'minus-circle']"
                                 class="text-negative text-lg cursor-pointer mt-10 ml-auto hover:text-primary-over"
                                 @click="toggleRemoveMeasurement(v, 'general')"/>
              <div class="arrows-container mt-6 ml-2">
                <font-awesome-icon :icon="['fal', 'chevron-up']" class="cursor-pointer"
                                   @click="updateMeasurementOrderGeneral('up', v)"/>
                <font-awesome-icon :icon="['fal', 'chevron-down']" class="cursor-pointer"
                                   @click="updateMeasurementOrderGeneral('down', v)"/>
              </div>
            </div>
          </div>
        </div>
      </Form>
      <div class="tables-container">
        <div class="table">
          <SectionHeader :onclick="toggleAddArticle" :title="$t('blinds.articles')" button="Add"/>
          <vue-good-table
              :columns="article_columns"
              :isLoading.sync="is_loading_articles"
              :pagination-options="{
                            enabled: false,
                            mode: 'records'
                        }"
              :rows="articles"
              :search-options="{
                            enabled: false,
                        }"
              :sort-options="{
                          enabled: false,
                        }"
              :totalRows="totalArticleRecords"
              max-height="500px"
              mode="remote"
              styleClass="vgt-table vgt-custom vgt-white">
            <template slot="table-row" slot-scope="props">
              <div v-if="props.column.field === 'attributes'">
                <p>{{ props.row.attributes.name }} - {{ props.row.attributes.code }}</p>
              </div>
              <div v-else-if="props.column.field === 'after'" class="td-after">
                <font-awesome-icon :icon="['fal', 'minus-circle']" @click="toggleRemoveArticle(props.row.id)"/>

                <div class="arrows-container">
                  <font-awesome-icon :icon="['fal', 'chevron-up']" @click="updateArticleOrder('up', props.row.id)"/>
                  <font-awesome-icon :icon="['fal', 'chevron-down']" @click="updateArticleOrder('down', props.row.id)"/>
                </div>
              </div>
              <span v-else>
                                {{ props.formattedRow[props.column.field] }}
                            </span>
            </template>
          </vue-good-table>
        </div>
        <div class="table">
          <SectionHeader :onclick="toggleAddFabric" :title="$t('blinds.fabrics')" button="Add"/>
          <vue-good-table
              :columns="fabric_columns"
              :isLoading.sync="is_loading_fabrics"
              :pagination-options="{
                            enabled: false,
                            mode: 'records'
                         }"
              :rows="fabrics"
              :search-options="{
                            enabled: false,
                        }"
              :sort-options="{
                          enabled: false,
                        }"
              :totalRows="totalFabricRecords"
              max-height="500px"
              mode="remote"
              styleClass="vgt-table vgt-custom vgt-white">
            <template slot="table-row" slot-scope="props">
              <div v-if="props.column.field === 'attributes'">
                <p>{{ props.row.attributes.name }} - {{ props.row.attributes.code }}</p>
              </div>
              <div v-else-if="props.column.field === 'after'" class="td-after">
                <font-awesome-icon :icon="['fal', 'minus-circle']" @click="toggleRemoveFabric(props.row.id)"/>

                <div class="arrows-container">
                  <font-awesome-icon :icon="['fal', 'chevron-up']" @click="updateFabricOrder('up', props.row.id)"/>
                  <font-awesome-icon :icon="['fal', 'chevron-down']" @click="updateFabricOrder('down', props.row.id)"/>
                </div>
              </div>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import _ from 'lodash';
import Headbar from "../../components/headbar/Headbar";
import Form from "../../components/form/Form";
import SectionHeader from "../../components/SectionHeader";
import Button from "../../components/Button";
import FormInputText from "../../components/form/FormInputText";
import FormInputSelect from "../../components/form/FormInputSelect";
import {required, email, numeric, minValue, decimal, requiredIf} from 'vuelidate/lib/validators'
import FormGroupTwo from "../../components/form/FormGroupTwo";
import FormInputPassword from "../../components/form/FormInputPassword";
import FormGroupThree from "@/components/form/FormGroupThree";
import AssignArticleModal from "@/components/blinds/AssignArticleModal";
import ConfirmModal from "@/components/modal/ConfirmModal";
import AssignFabricModal from "@/components/blinds/AssignFabricModal";
import AssignMeasurementModal from "@/components/blinds/AssignMeasurementModal.vue";

export default {
  name: "blinds-update-page",
  components: {
    FormGroupThree,
    FormGroupTwo, FormInputText, FormInputPassword, FormInputSelect, Button, SectionHeader, Form, Headbar
  },
  data: function () {
    const article_columns = [
      {
        label: this.$t('blinds.name'),
        field: 'attributes',
        sortable: false,
      },
      {
        field: 'after',
        sortable: false,
      },
    ];

    const fabric_columns = [
      {
        label: this.$t('blinds.name'),
        field: 'attributes',
        sortable: false,
      },
      {
        field: 'after',
        sortable: false,
      },
    ];

    return {
      original: null,
      user: {
        name: null,
        company: null,
        phone: null,
        email: null,
        address: null,
        password: null,
        role: null,
      },
      blind: {
        name: null,
        type: null,
        top_tube_diameter: null,
        width_divider: null,
      },


      measurements_back_bar: [],
      measurements_brackets: [],
      measurements_cover: [],

      measurements_general: [],

      measurementTabs: ['Brackets', 'Back Bar', 'Cover'],
      currentMeasurementTab: 'Brackets',

      measurementTabsControl: ['Cord', 'Wand'],
      currentMeasurementTabControl: 'Cord',
      measurements_cord: [],
      measurements_wand: [],

      blindTypeOptions: [],

      articles: [],
      article_columns: article_columns,
      totalArticleRecords: null,
      article_page: 1,

      fabrics: [],
      fabric_columns: fabric_columns,
      totalFabricRecords: null,
      fabric_page: 1,

      is_saving: false,
      is_removing_article: false,
      is_loading_original: false,
      is_loading_blind_types: false,
      is_loading_articles: false,
      is_loading_fabrics: false,
    }
  },
  validations: {
    blind: {
      name: {required},
      type: {required},
      top_tube_diameter: {},
      width_divider: {
        required: requiredIf(function () {
          if (!this?.original?.attributes?.type) return false
          return this.original.attributes.type.includes('vertical') || this.original.attributes.type.includes('curtain') || this.original.attributes.type.includes('venetian')
        })
      },
    },
    measurements_back_bar: {
      $each: {
        id: {},
        name: {},
        value: {required, decimal, minValue: minValue(0)}
      }
    },
    measurements_brackets: {
      $each: {
        id: {},
        name: {},
        value: {required, decimal, minValue: minValue(0)}
      }
    },
    measurements_cover: {
      $each: {
        id: {},
        name: {},
        value: {required, decimal, minValue: minValue(0)}
      }
    },
    measurements_cord: {
      $each: {
        id: {},
        name: {},
        value: {required, decimal, minValue: minValue(0)}
      }
    },
    measurements_wand: {
      $each: {
        id: {},
        name: {},
        value: {required, decimal, minValue: minValue(0)}
      }
    },
    measurements_general: {
      $each: {
        id: {},
        name: {},
        value: {required, decimal, minValue: minValue(0)}
      }
    },
  },
  methods: {
    populate() {
      if (!this.original || !this.blind)
        return;

      this.$v.blind.name.$model = this.original.attributes.name;
      this.$v.blind.top_tube_diameter.$model = this.original.attributes.top_tube_diameter;
      this.$v.blind.type.$model = _.find(this.blindTypeOptions, {column: this.original.attributes.type});

      this.$nextTick(async () => {
        if (this.showWidthDivider) this.$v.blind.width_divider.$model = this.original.attributes.width_divider
      });
    },
    updateArticleOrder(direction, article_id) {
      let article = this.articles.findIndex(a => a.id === article_id);

      for (let i = 0; i < this.articles.length; i++) {
        this.articles[i].attributes.order = i;
      }

      if (direction === 'up') {
        if (this.articles[article].attributes.order > 0) {
          this.articles[article].attributes.order--;
          this.articles[article - 1].attributes.order++;
          this.syncArticles();
        }
      } else if (direction === 'down') {
        if (this.articles[article].attributes.order < this.articles.length - 1) {
          this.articles[article].attributes.order++;
          this.articles[article + 1].attributes.order--;
          this.syncArticles();
        }
      }
    },
    syncArticles() {
      let article_ids = [];

      this.articles.forEach(a => {
        article_ids.push({id: a.id, order: a.attributes.order})
      })

      this.$axios.put(`blinds/${this.$route.params.id}/articles/sync`, {articles: article_ids}).then(({data}) => {
        this.retrieveOriginalBlind();

        this.is_saving = false;
        this.close(true);
      }).catch(e => {
        this.is_saving = false;
      });
    },
    updateFabricOrder(direction, fabric_id) {
      let fabric = this.fabrics.findIndex(f => f.id === fabric_id);

      for (let i = 0; i < this.fabrics.length; i++) {
        this.fabrics[i].attributes.order = i;
      }

      if (direction === 'up') {
        if (this.fabrics[fabric].attributes.order > 0) {
          this.fabrics[fabric].attributes.order--;
          this.fabrics[fabric - 1].attributes.order++;
          this.syncFabrics();
        }
      } else if (direction === 'down') {
        if (this.fabrics[fabric].attributes.order < this.fabrics.length - 1) {
          this.fabrics[fabric].attributes.order++;
          this.fabrics[fabric + 1].attributes.order--;
          this.syncFabrics();
        }
      }
    },
    updateMeasurementOrderGeneral(direction, measurement) {
      let measurementIndex = this.measurements_general.findIndex(f => f.id === measurement.id.$model);

      if (direction === 'up' && measurementIndex > 0) {
        [this.measurements_general[measurementIndex], this.measurements_general[measurementIndex - 1]] = [this.measurements_general[measurementIndex - 1], this.measurements_general[measurementIndex]];
      } else if (direction === 'down' && measurementIndex < this.$v.measurements_general.$model.length - 1) {
        [this.measurements_general[measurementIndex], this.measurements_general[measurementIndex + 1]] = [this.measurements_general[measurementIndex + 1], this.measurements_general[measurementIndex]];

      }
      this.$v.measurements_general.$model = [...this.measurements_general]
    },
    updateMeasurementCord(direction, measurement) {
      let measurementIndex = this.measurements_cord.findIndex(f => f.id === measurement.id.$model);

      if (direction === 'up' && measurementIndex > 0) {
        [this.measurements_cord[measurementIndex], this.measurements_cord[measurementIndex - 1]] = [this.measurements_cord[measurementIndex - 1], this.measurements_cord[measurementIndex]];
      } else if (direction === 'down' && measurementIndex < this.$v.measurements_cord.$model.length - 1) {
        [this.measurements_cord[measurementIndex], this.measurements_cord[measurementIndex + 1]] = [this.measurements_cord[measurementIndex + 1], this.measurements_cord[measurementIndex]];

      }
      this.$v.measurements_cord.$model = [...this.measurements_cord]
    },
    updateMeasurementOrderWand(direction, measurement) {
      let measurementIndex = this.measurements_wand.findIndex(f => f.id === measurement.id.$model);

      if (direction === 'up' && measurementIndex > 0) {
        [this.measurements_wand[measurementIndex], this.measurements_wand[measurementIndex - 1]] = [this.measurements_wand[measurementIndex - 1], this.measurements_wand[measurementIndex]];
      } else if (direction === 'down' && measurementIndex < this.$v.measurements_wand.$model.length - 1) {
        [this.measurements_wand[measurementIndex], this.measurements_wand[measurementIndex + 1]] = [this.measurements_wand[measurementIndex + 1], this.measurements_wand[measurementIndex]];

      }
      this.$v.measurements_wand.$model = [...this.measurements_wand]
    },
    updateMeasurementOrderBackBar(direction, measurement) {
      let measurementIndex = this.measurements_back_bar.findIndex(f => f.id === measurement.id.$model);

      if (direction === 'up' && measurementIndex > 0) {
        [this.measurements_back_bar[measurementIndex], this.measurements_back_bar[measurementIndex - 1]] = [this.measurements_back_bar[measurementIndex - 1], this.measurements_back_bar[measurementIndex]];
      } else if (direction === 'down' && measurementIndex < this.$v.measurements_back_bar.$model.length - 1) {
        [this.measurements_back_bar[measurementIndex], this.measurements_back_bar[measurementIndex + 1]] = [this.measurements_back_bar[measurementIndex + 1], this.measurements_back_bar[measurementIndex]];

      }
      this.$v.measurements_back_bar.$model = [...this.measurements_back_bar]
    },
    updateMeasurementOrderBrackets(direction, measurement) {
      let measurementIndex = this.measurements_brackets.findIndex(f => f.id === measurement.id.$model);

      if (direction === 'up' && measurementIndex > 0) {
        [this.measurements_brackets[measurementIndex], this.measurements_brackets[measurementIndex - 1]] = [this.measurements_brackets[measurementIndex - 1], this.measurements_brackets[measurementIndex]];
      } else if (direction === 'down' && measurementIndex < this.$v.measurements_brackets.$model.length - 1) {
        [this.measurements_brackets[measurementIndex], this.measurements_brackets[measurementIndex + 1]] = [this.measurements_brackets[measurementIndex + 1], this.measurements_brackets[measurementIndex]];

      }
      this.$v.measurements_brackets.$model = [...this.measurements_brackets]
    },
    updateMeasurementOrderCover(direction, measurement) {
      let measurementIndex = this.measurements_cover.findIndex(f => f.id === measurement.id.$model);

      if (direction === 'up' && measurementIndex > 0) {
        [this.measurements_cover[measurementIndex], this.measurements_cover[measurementIndex - 1]] = [this.measurements_cover[measurementIndex - 1], this.measurements_cover[measurementIndex]];
      } else if (direction === 'down' && measurementIndex < this.$v.measurements_cover.$model.length - 1) {
        [this.measurements_cover[measurementIndex], this.measurements_cover[measurementIndex + 1]] = [this.measurements_cover[measurementIndex + 1], this.measurements_cover[measurementIndex]];

      }
      this.$v.measurements_cover.$model = [...this.measurements_cover]
    },
    syncFabrics() {
      let fabric_ids = [];

      this.fabrics.forEach(a => {
        fabric_ids.push({id: a.id, order: a.attributes.order})
      })

      this.$axios.put(`blinds/${this.$route.params.id}/fabrics/sync`, {fabrics: fabric_ids}).then(({data}) => {
        this.retrieveOriginalBlind();

        this.is_saving = false;
        this.close(true);
      }).catch(e => {
        this.is_saving = false;
      });
    },
    async retrieveOriginalUser() {
      this.is_loading_original = false;
      await this.$axios.get(`users/${this.$route.params.id}`)
          .then(({data}) => {
            this.original = data.data;
            this.is_loading_original = false;
          })
          .catch(e => {
            this.is_loading_original = false;

            this.$notify({
              title: this.$t('error'),
              text: this.$larerror(e.response.data, this.$t('users.error_retrieve')),
              type: 'error',
            });
          });
    },
    async retrieveOriginalBlind() {
      this.is_loading_original = false;
      await this.$axios.get(`blinds/${this.$route.params.id}`)
          .then(async ({data}) => {
            this.original = data.data;

            if (this.original.attributes.type.includes('zebra_blind')) {
              this.measurementTabs = ['Back Bar', 'Cover']
              this.currentMeasurementTab = 'Back Bar'

            }

            this.articles = data.data.relationships.articles.data.sort((a, b) => a.attributes.order - b.attributes.order);
            this.fabrics = data.data.relationships.fabrics.data.sort((a, b) => a.attributes.order - b.attributes.order);

            await this.$nextTick()

            if (this.hasMultipleMeasurements) {
              this.measurements_back_bar = this.original?.attributes?.measurements?.back_bar
              if (!this.measurements_back_bar) this.measurements_back_bar = []

              this.measurements_brackets = this.original?.attributes?.measurements?.brackets
              if (!this.measurements_brackets) this.measurements_brackets = []

              this.measurements_cover = this.original?.attributes?.measurements?.cover
              if (!this.measurements_cover) this.measurements_cover = []

            } else if (this.hasControlMeasurements) {
              this.measurements_cord = this.original?.attributes?.measurements?.cord
              if (!this.measurements_cord) this.measurements_cord = []

              this.measurements_wand = this.original?.attributes?.measurements?.wand
              if (!this.measurements_wand) this.measurements_wand = []
            } else {
              this.measurements_general = this.original?.attributes?.measurements?.general
              if (!this.measurements_general) this.measurements_general = []
            }

            this.populate();

            this.$v.$reset();

            this.is_loading_original = false;
          })
          .catch(e => {
            this.is_loading_original = false;

            this.$notify({
              title: this.$t('error'),
              text: this.$larerror(e.response.data, this.$t('blinds.error_retrieve')),
              type: 'error',
            });
          });
    },
    async retrieveBlindTypes() {
      this.is_loading_blind_types = true;
      await this.$axios.get(`blinds/list-types`)
          .then(({data}) => {
            this.blindTypeOptions = data;
            this.is_loading_blind_types = false;
          })
          .catch(e => {
            this.is_loading_blind_types = false;

            this.$notify({
              title: this.$t('error'),
              text: this.$larerror(e.response.data, this.$t('blinds.error_retrieve_blind_types')),
              type: 'error',
            });
          });
    },
    toggleAddArticle() {
      this.$modal.show(
          AssignArticleModal, {},
          {
            name: 'assign-article-modal',
            adaptive: true,
            resizable: true,
            height: 'auto',
            scrollable: true,
            classes: 'modal',
          }, {
            'before-close': (e) => {
              if (e.params === true)
                this.retrieveOriginalBlind();
            }
          }
      );
    },
    toggleAddMeasurement() {
      if (this.hasMultipleMeasurements) {
        if (this.currentMeasurementTab === 'Back Bar') this.toggleAddMeasurementBackBar()
        else if (this.currentMeasurementTab === 'Brackets') this.toggleAddMeasurementBrackets()
        else if (this.currentMeasurementTab === 'Cover') this.toggleAddMeasurementCover()
      } else if (this.hasControlMeasurements) {
        if (this.currentMeasurementTabControl === 'Cord') this.toggleAddMeasurementCord()
        else if (this.currentMeasurementTabControl === 'Wand') this.toggleAddMeasurementWand()
      } else {
        this.toggleAddMeasurementGeneral()
      }
    },
    toggleAddMeasurementGeneral() {
      this.$modal.show(
          AssignMeasurementModal, {
            measurements: this.measurements_general
          },
          {
            name: 'assign-measurement-modal',
            adaptive: true,
            resizable: true,
            height: 'auto',
            scrollable: true,
            classes: 'modal',

          }, {
            'before-close': (e) => {
              if (e.params && e.params.status === true && e.params.item) {
                this.measurements_general.push(e.params.item)
              }
            }
          }
      );
    },
    toggleAddMeasurementBackBar() {
      this.$modal.show(
          AssignMeasurementModal, {
            measurements: this.measurements_back_bar
          },
          {
            name: 'assign-measurement-modal',
            adaptive: true,
            resizable: true,
            height: 'auto',
            scrollable: true,
            classes: 'modal',

          }, {
            'before-close': (e) => {
              if (e.params && e.params.status === true && e.params.item) {
                this.measurements_back_bar.push(e.params.item)
              }
            }
          }
      );
    },
    toggleAddMeasurementBrackets() {
      this.$modal.show(
          AssignMeasurementModal, {
            measurements: this.measurements_brackets
          },
          {
            name: 'assign-measurement-modal',
            adaptive: true,
            resizable: true,
            height: 'auto',
            scrollable: true,
            classes: 'modal',

          }, {
            'before-close': (e) => {
              if (e.params && e.params.status === true && e.params.item) {
                this.measurements_brackets.push(e.params.item)
              }
            }
          }
      );
    },
    toggleAddMeasurementCover() {
      this.$modal.show(
          AssignMeasurementModal, {
            measurements: this.measurements_cover
          },
          {
            name: 'assign-measurement-modal',
            adaptive: true,
            resizable: true,
            height: 'auto',
            scrollable: true,
            classes: 'modal',

          }, {
            'before-close': (e) => {
              if (e.params && e.params.status === true && e.params.item) {
                this.measurements_cover.push(e.params.item)
              }
            }
          }
      );
    },
    toggleAddMeasurementCord() {
      this.$modal.show(
          AssignMeasurementModal, {
            measurements: this.measurements_cord
          },
          {
            name: 'assign-measurement-modal',
            adaptive: true,
            resizable: true,
            height: 'auto',
            scrollable: true,
            classes: 'modal',

          }, {
            'before-close': (e) => {
              if (e.params && e.params.status === true && e.params.item) {
                this.measurements_cord.push(e.params.item)
              }
            }
          }
      );
    },
    toggleAddMeasurementWand() {
      this.$modal.show(
          AssignMeasurementModal, {
            measurements: this.measurements_wand
          },
          {
            name: 'assign-measurement-modal',
            adaptive: true,
            resizable: true,
            height: 'auto',
            scrollable: true,
            classes: 'modal',

          }, {
            'before-close': (e) => {
              if (e.params && e.params.status === true && e.params.item) {
                this.measurements_wand.push(e.params.item)
              }
            }
          }
      );
    },
    toggleRemoveMeasurement(measurement, type) {
      this.$modal.show(
          ConfirmModal, {
            title: this.$t('blinds.remove_measurement'),
            message: this.$t('blinds.prompt_remove_measurement'),
            confirmText: this.$t('remove'),
            cancelText: this.$t('cancel'),
            confirmClass: '--primary',
            cancelClass: '--secondary --outline'
          },
          {
            name: 'confirm-modal',
            adaptive: true,
            resizable: true,
            height: 'auto',
            scrollable: true,
            classes: 'modal',
          }, {
            'before-close': (e) => {
              if (e.params === true) {
                if (type === 'back_bar') this.measurements_back_bar = this.measurements_back_bar.filter(x => x.id !== measurement.id.$model)
                else if (type === 'brackets') this.measurements_brackets = this.measurements_brackets.filter(x => x.id !== measurement.id.$model)
                else if (type === 'cover') this.measurements_cover = this.measurements_cover.filter(x => x.id !== measurement.id.$model)
                else if (type === 'cord') this.measurements_cord = this.measurements_cord.filter(x => x.id !== measurement.id.$model)
                else if (type === 'wand') this.measurements_wand = this.measurements_wand.filter(x => x.id !== measurement.id.$model)
                else if (type === 'general') this.measurements_general = this.measurements_general.filter(x => x.id !== measurement.id.$model)
              }
            }
          }
      );
    },
    toggleRemoveArticle(id) {
      this.$modal.show(
          ConfirmModal, {
            title: this.$t('blinds.remove_article'),
            message: this.$t('blinds.prompt_remove_article'),
            confirmText: this.$t('remove'),
            cancelText: this.$t('cancel'),
            confirmClass: '--primary',
            cancelClass: '--secondary --outline'
          },
          {
            name: 'confirm-modal',
            adaptive: true,
            resizable: true,
            height: 'auto',
            scrollable: true,
            classes: 'modal',
          }, {
            'before-close': (e) => {
              if (e.params === true) {
                this.is_removing_article = true;
                this.$axios.delete(`blinds/${this.$route.params.id}/articles/${id}/detach`)
                    .then(({data}) => {
                      this.is_removing_article = false;
                      this.retrieveOriginalBlind();
                    })
                    .catch(e => {
                      this.is_removing_article = false;

                      this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('blinds.error_remove_article')),
                        type: 'error',
                      });
                    });
              }
            }
          }
      );
    },
    toggleAddFabric() {
      this.$modal.show(
          AssignFabricModal, {},
          {
            name: 'assign-fabric-modal',
            adaptive: true,
            resizable: true,
            height: 'auto',
            scrollable: true,
            classes: 'modal',
          }, {
            'before-close': (e) => {
              if (e.params === true)
                this.retrieveOriginalBlind();
            }
          }
      );
    },
    toggleRemoveFabric(id) {
      this.$modal.show(
          ConfirmModal, {
            title: this.$t('blinds.remove_fabric'),
            message: this.$t('blinds.prompt_remove_fabric'),
            confirmText: this.$t('remove'),
            cancelText: this.$t('cancel'),
            confirmClass: '--primary',
            cancelClass: '--secondary --outline'
          },
          {
            name: 'confirm-modal',
            adaptive: true,
            resizable: true,
            height: 'auto',
            scrollable: true,
            classes: 'modal',
          }, {
            'before-close': (e) => {
              if (e.params === true) {
                this.is_removing_fabric = true;
                this.$axios.delete(`blinds/${this.$route.params.id}/fabrics/${id}/detach`)
                    .then(({data}) => {
                      this.is_removing_fabric = false;
                      this.retrieveOriginalBlind();
                    })
                    .catch(e => {
                      this.is_removing_fabric = false;

                      this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('blinds.error_remove_fabric')),
                        type: 'error',
                      });
                    });
              }
            }
          }
      );
    },
    save() {
      if (this.hasMultipleMeasurements) {
        this.$v.measurements_back_bar.$touch();
        if (this.$v.measurements_back_bar.$anyError || this.is_saving) return;

        this.$v.measurements_brackets.$touch();
        if (this.$v.measurements_brackets.$anyError || this.is_saving) return;

        this.$v.measurements_cover.$touch();
        if (this.$v.measurements_cover.$anyError || this.is_saving) return;
      } else if (this.hasControlMeasurements) {
        this.$v.measurements_cord.$touch();
        if (this.$v.measurements_cord.$anyError || this.is_saving) return;

        this.$v.measurements_wand.$touch();
        if (this.$v.measurements_wand.$anyError || this.is_saving) return;
      } else {
        this.$v.measurements_general.$touch();
        if (this.$v.measurements_general.$anyError || this.is_saving) return;
      }

      this.$v.blind.$touch();
      if (this.$v.blind.$anyError || this.is_saving) return;

      this.is_saving = true;

      const payload = Object.assign({}, this.$v.blind.$model);
      if (!this.showWidthDivider && payload && payload.attributes && payload.attributes.width_divider) delete payload.attributes.width_divider
      payload.measurements = {}
      if (this.hasMultipleMeasurements) {
        payload.measurements.back_bar = this.$v.measurements_back_bar.$model
        payload.measurements.brackets = this.$v.measurements_brackets.$model
        payload.measurements.cover = this.$v.measurements_cover.$model
      } else if (this.hasControlMeasurements) {
        payload.measurements.cord = this.$v.measurements_cord.$model
        payload.measurements.wand = this.$v.measurements_wand.$model
      } else {
        payload.measurements.general = this.$v.measurements_general.$model
      }

      payload.type = this.blind.type.column;

      this.$axios.patch(`blinds/${this.$route.params.id}`, payload).then(({data}) => {
        this.$notify({
          text: this.$t('blinds.success_updated'),
          type: 'success',
        });

        this.is_saving = false;
      }).catch(e => {
        this.is_saving = false;

        this.$notify({
          title: this.$t('error'),
          text: this.$larerror(e.response.data.errors, this.$t('blinds.error_update')),
          type: 'error',
        });
      });
    },
  },
  computed: {
    hasMultipleMeasurements() {
      if (!this.original) return false
      return this.original.attributes.type.includes('roller_blind') || this.original.attributes.type.includes('zebra_blind')
    },
    hasControlMeasurements() {
      if (!this.original) return false
      return this.original.attributes.type.includes('curtain')
    },
    showWidthDivider() {
      if (!this.original) return false
      return this.original.attributes.type.includes('vertical') || this.original.attributes.type.includes('curtain') || this.original.attributes.type.includes('venetian')
    }
  },

  async mounted() {
    await this.retrieveBlindTypes();
    await this.retrieveOriginalBlind();
  },

  head() {
    return {
      title: {
        inner: this.$t('blinds.update_blind')
      },
    }
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  main {
    @apply flex flex-col;

    .form {
      @apply mb-8;

      .form-body {
        @apply px-8 py-7 max-w-6xl;
      }
    }

    .tables-container {
      @apply flex flex-col;

      @screen lg {
        @apply flex-row flex-wrap;
      }

      .table {
        @apply mb-8;

        @screen lg {
          width: calc(50% - 1rem);
        }

        &:nth-child(odd) {
          @screen lg {
            @apply mr-4;
          }
        }

        &:nth-child(even) {
          @screen lg {
            @apply ml-4;
          }
        }

        .td-after {
          @apply flex;

          svg {
            @apply text-negative text-lg cursor-pointer ml-auto my-auto;

            &:hover {
              @apply text-primary-over
            }
          }

          .arrows-container {
            @apply flex flex-col my-auto ml-4;

            & > svg {
              @apply text-grey-dark;

              &.disabled {
                @apply text-grey;
              }
            }
          }
        }
      }
    }

  }
}

.input-group-three-div {
  @apply flex flex-row items-start flex-wrap w-auto;

  @screen md {
    @apply -mx-2;
  }

  .input-group-div {
    @apply w-full px-0 mb-6;

    @screen md {
      @apply w-1/2 px-2;
    }

    @screen xl {
      @apply w-1/3;
    }
  }
}
</style>